<template>
  <div>
    <div v-if="isFolderOrGeneralSettings && isDataChanged">
      <b-button
        @click="saveSettings"
        class="mr-2 green-button-link"
        :disabled="isSaveButtonDisabled"
      >
        <b-icon icon="cloud-upload" aria-hidden="true" class="mr-1"></b-icon>
        {{ $t('buttons.save') }}
      </b-button>
      <b-button
        @click="cancelSettingsUpdate"
        class="mr-4 transparent-white-button"
      >
        <b-icon icon="x-circle" aria-hidden="true" class="top--1 mr-1"></b-icon>
        {{ $t('buttons.cancel') }}
      </b-button>
    </div>
    <div
      v-if="isDataChanged
      && (currentPageType === 'project-settings' || currentPageType === 'page-settings')">
      <b-button
        v-if="!isDataPublished"
        @click="saveSettings"
        class="mr-2 green-button-link"
        :disabled="isSaveButtonDisabled"
      >
        <b-icon icon="cloud-upload" aria-hidden="true" class="mr-1"></b-icon>
        {{ $t('buttons.save') }}
      </b-button>
      <b-button
        v-else
        @click="saveSettings"
        class="mr-2 green-button-link"
        :disabled="isSaveButtonDisabled"
      >
        <b-icon icon="cloud-upload" aria-hidden="true" class="mr-1"></b-icon>
        {{ $t('buttons.saveAndPublish') }}
      </b-button>

      <b-button
        @click="cancelSettingsUpdate"
        class="mr-4 transparent-white-button"
      >
        <b-icon icon="x-circle" aria-hidden="true" class="mr-1 top--1"></b-icon>
        {{ $t('buttons.cancel') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import GetButtonDisabledFromCache from '@/graphQlQueries/queries/getButtonDisabledFromCache';
import GetDataHasChangedForSavingFromCache from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';
import GetPageTypeFromCache from '@/graphQlQueries/queries/getPageTypeFromCache';

import SetDataChangedInCache from '@/mixins/setDataChangedInCache';

export default {
  name: 'HeaderActionSaveButtons',
  mixins: [SetDataChangedInCache],
  data: () => ({
    isDataChanged: false,
    isDataPublished: false,
    isSaveButtonDisabled: false,
  }),
  apollo: {
    currentPageType: {
      query: GetPageTypeFromCache,
      update(data) {
        return (data.pageType)
          ? data.pageType.type
          : null;
      },
    },
    hasDataForSavingChangedObject: {
      query: GetDataHasChangedForSavingFromCache,
      update(data) {
        this.isDataChanged = data.dataHasChangedForSaving.isDataChanged;
        this.isDataPublished = data.dataHasChangedForSaving.isDataPublished;
        return data;
      },
    },
    isSaveButtonDisabled: {
      query: GetButtonDisabledFromCache,
      update(data) {
        return data.buttonDisabled.isButtonDisabled;
      },
    },
  },
  computed: {
    isFolderOrGeneralSettings() {
      return this.currentPageType === 'folder-settings' || this.currentPageType === 'general-settings';
    },
  },
  methods: {
    saveSettings() {
      this.setDataChangedInCache({
        pageType: this.currentPageType,
        isDataChanged: this.isDataChanged,
        isSubmittedButtonPressed: true,
        isCancelButtonPressed: false,
        isDataPublished: this.isDataPublished,
      });
    },
    cancelSettingsUpdate() {
      this.setDataChangedInCache({
        pageType: this.currentPageType,
        isDataChanged: this.isDataChanged,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: true,
        isDataPublished: this.isDataPublished,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  text-transform: uppercase;
}

.disabled {
  cursor: not-allowed;
}
</style>
