var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isFolderOrGeneralSettings && _vm.isDataChanged
      ? _c(
          "div",
          [
            _c(
              "b-button",
              {
                staticClass: "mr-2 green-button-link",
                attrs: { disabled: _vm.isSaveButtonDisabled },
                on: { click: _vm.saveSettings }
              },
              [
                _c("b-icon", {
                  staticClass: "mr-1",
                  attrs: { icon: "cloud-upload", "aria-hidden": "true" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("buttons.save")) + " ")
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-4 transparent-white-button",
                on: { click: _vm.cancelSettingsUpdate }
              },
              [
                _c("b-icon", {
                  staticClass: "top--1 mr-1",
                  attrs: { icon: "x-circle", "aria-hidden": "true" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isDataChanged &&
    (_vm.currentPageType === "project-settings" ||
      _vm.currentPageType === "page-settings")
      ? _c(
          "div",
          [
            !_vm.isDataPublished
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-2 green-button-link",
                    attrs: { disabled: _vm.isSaveButtonDisabled },
                    on: { click: _vm.saveSettings }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "cloud-upload", "aria-hidden": "true" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("buttons.save")) + " ")
                  ],
                  1
                )
              : _c(
                  "b-button",
                  {
                    staticClass: "mr-2 green-button-link",
                    attrs: { disabled: _vm.isSaveButtonDisabled },
                    on: { click: _vm.saveSettings }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "cloud-upload", "aria-hidden": "true" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("buttons.saveAndPublish")) + " ")
                  ],
                  1
                ),
            _c(
              "b-button",
              {
                staticClass: "mr-4 transparent-white-button",
                on: { click: _vm.cancelSettingsUpdate }
              },
              [
                _c("b-icon", {
                  staticClass: "mr-1 top--1",
                  attrs: { icon: "x-circle", "aria-hidden": "true" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }